import Cookies from 'js-cookie';

import showModal from '../modals';

const COOKIE_NAME = 'custom-user-modal-cookie';

export default function userCustomModal() {
    const userCustomContent = document.querySelector('.user-modal-content');

    if (userCustomContent) {
        const html = userCustomContent.innerHTML;

        const onCloseModal = function () {
            Cookies.set(COOKIE_NAME, '1', { expires: 1 });
        }

        const currentPreferences = Cookies.get('gs_privacy_preferences');

        if (currentPreferences) {
            setTimeout(function () {
                showModal(html, '', null, onCloseModal);
            }, 2000);
        }
    }
}
