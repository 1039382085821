import MoveTo from 'moveto';

import openModal from '../modals';
import { showLoader, hideLoader } from '../utils/utils';

const commentFormSubmit = form => {
    showLoader();

    const productId = form.getAttribute('data-product-id');
    const positionId = form.getAttribute('data-position-id');
    const token = form.getAttribute('data-token');
    const request = {
        Rating: Number(form.elements['product-comment-rating'].value),
        RatingUser: form.elements['product-comment-author'].value,
        RatingBody: form.elements['product-comment-opinion'].value,
        PositionId: positionId,
        ProductId: productId,
        Token: token,
    };

    fetch('/api/frontend/RateProduct', {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify(request),
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(data => {
            form.closest('.single-comment-row').classList.add('product-commented');
            hideLoader();
            openModal(data.message);
        })
        .catch(error => {
            console.log(error);
            hideLoader();
        });
};

export default function () {
    const addCommentForm = document.querySelectorAll('.add-comment-form');

    addCommentForm.forEach(form => {
        form.querySelectorAll('.stars-rating li').forEach((star, index) => {
            star.addEventListener('click', function (e) {
                const starIndex = index + 1;
                const starsContainer = this.parentElement.parentElement;

                starsContainer.querySelectorAll('li').forEach(li => li.classList.remove('rated'));

                for (let i = 0; i < starIndex; i++) {
                    starsContainer.querySelectorAll('li')[i].classList.add('rated');
                }

                form.querySelector('[name=product-comment-rating]').value = starIndex;
                e.preventDefault();
            });
        });
    });

    const comments = document.querySelectorAll('.comment');

    if (comments.length > 0) {
        comments.forEach(comment => {
            const starsContainer = comment.getElementsByClassName('stars-rating')[0];
            const starRating = starsContainer.getAttribute('data-rating');
            let newStars = '';

            for (let i = 1; i <= 5; i++) {
                i <= starRating ? newStars += '<li class="rated"></li>' : newStars += '<li></li>';
            }

            starsContainer.querySelectorAll('ul')[0].innerHTML = newStars;
        });
    }

    const averageRating = document.querySelectorAll('.average-rating');

    averageRating.forEach(rating => {
        const starsContainer = rating;
        const starsRating = (starsContainer.getAttribute('data-rating') * 20).toFixed(2, 10);

        starsContainer.querySelectorAll('div')[0].style.width = `${starsRating}%`;
        starsContainer.querySelectorAll('div')[1].style.width = `${100 - starsRating}%`;

        if (rating.parentElement.classList.contains('detail-stars-wrapper')) {
            rating.addEventListener('click', function () {
                const target = document.querySelector('.detail-accordion-row:last-child');
                const moveTo = new MoveTo({
                    tolerance: window.scrollOffset,
                    callback: function () {
                        target.querySelector('.detail-accordion-trigger').click();
                    }
                });

                moveTo.move(target);
            });
        }
    });
}

export { commentFormSubmit };