import { on } from 'delegated-events';

let detailDiscontTable;

function whichDiscount(amount) {
    let whichRow = null;

    document.querySelectorAll('[data-discount-table-pcs]').forEach((data, index) => {
        const dataAttr = +data.getAttribute('data-discount-table-pcs');

        if (amount >= dataAttr) {
            whichRow = index;
        }

        data.addEventListener('click', function () {
            document.querySelector('.detail-amount input').value = +dataAttr;
            detailDiscontTable.querySelectorAll('tr').forEach(row => row.classList.remove('selected'));
            data.classList.add('selected');
        });
    });

    const rows = detailDiscontTable.querySelectorAll('tr');

    rows.forEach(row => row.classList.remove('selected'));

    if (whichRow || whichRow === 0) {
        rows.forEach((row, index) => {
            if (index === whichRow) {
                row.classList.add('selected');
            }
        });
    }
}

export default function () {
    detailDiscontTable = document.querySelector('.detail-discount-table');

    if (detailDiscontTable) whichDiscount(1);

    on('click', '.amount-handler-wrapper button', function () {
        const isDetailAmount = this.parentElement.classList.contains('detail-amount');
        const inputField = this.parentElement.querySelector('input');

        if (this.classList.contains('plus')) {
            inputField.value = Number(inputField.value) + 1;
            if (isDetailAmount) whichDiscount(+inputField.value);
        } else {
            if (Number(inputField.value) > 1) {
                inputField.value = Number(inputField.value) - 1;
                if (isDetailAmount) whichDiscount(+inputField.value);
            }
        }
    });

    on('click', '.amount-handler-wrapper button', function (e) {
        if (e.key === 'Enter') {
            this.click();
        }
    });

    on('keydown', '.detail-amount input', function (e) {
        if (e.key === 'Enter') {
            whichDiscount(+this.value);
        }
    });
}
